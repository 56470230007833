<template>
  <div class="lost">
    Whoops Looks like your headed in the wrong direction.
  </div>
</template>

<script>
export default {
  name: 'lost',
  docs(v) {
    return {
      props: v.props,
      events: {},
      slots: {},
      components: v.components,
    };
  },
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>

</style>
