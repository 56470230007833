<template>
  <div class="under-maintain">
    Under Maintenance
  </div>
<!--  <div class="prequalify">-->
<!--    <div class="bg-image" :style="{-->
<!--      backgroundImage: `url(${bg})`,-->
<!--    }"-->
<!--    />-->
<!--    <div class="page">-->
<!--      <div class="nav" v-if="landingInfo.contactNumber || landingInfo.logo">-->
<!--        <div class="nav__container">-->
<!--          <div class="logo"><img alt="logo" v-if="landingInfo.logo" :src="landingInfo.logo"/></div>-->
<!--          <div class="contact text-color-orange" v-if="landingInfo.contactNumber"><span class="call-out">Call Us:</span> {{ landingInfo.contactNumber }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="main">-->
<!--        <div class="details">-->
<!--          <div class="left">-->
<!--            <div class="top youtube-video-container">-->
<!--							<iframe class="main-video" width="560" height="315" src="https://www.youtube.com/embed/RziSksB5ncs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--            </div>-->
<!--            <div class="bottom circles">-->
<!--							<v-img class="circle" :src="circles.first"></v-img>-->
<!--							<v-img class="circle" :src="circles.second"></v-img>-->
<!--							<v-img class="circle" :src="circles.third"></v-img>-->
<!--&lt;!&ndash;              <div class="imgs"><v-img :src="circles"></v-img></div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="content__container">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="title">Do you have any of these symptoms?</div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="content">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="list">&ndash;&gt;-->
<!--&lt;!&ndash;                    <ul class="list__left">&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Foot/Ankle pain</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Shoulder pain</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Hip pain</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Knee pain</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Elbow pain</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Wrist pain</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Back pain</li>&ndash;&gt;-->
<!--&lt;!&ndash;                    </ul>&ndash;&gt;-->
<!--&lt;!&ndash;                    <ul class="list__right">&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Inflammation</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Osteoarthritis</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Tendonitis</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Bursitis</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Fasciitis</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Minor tears</li>&ndash;&gt;-->
<!--&lt;!&ndash;                      <li>Ligament damage</li>&ndash;&gt;-->
<!--&lt;!&ndash;                    </ul>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="call-out extra-big">WE CAN HELP!</div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="right main-call-out">-->
<!--&lt;!&ndash;            <h1 class="titled">If you are living with PAIN...</h1>&ndash;&gt;-->
<!--&lt;!&ndash;            <h2 class="text-color-orangeReal">STOP!</h2>&ndash;&gt;-->
<!--            <h3 class="titled__sub">Finally a solution that is:</h3>-->
<!--            <ul class="titled__list">-->
<!--              <li>-->
<!--                <v-icon class="text-color-orangeReal details-header__check">fan fa-check</v-icon>-->
<!--                Non-surgical-->
<!--              </li>-->
<!--              <li>-->
<!--                <v-icon class="text-color-orangeReal details-header__check">fan fa-check</v-icon>-->
<!--                Non-opioid</li>-->
<!--              <li>-->
<!--                <v-icon class="text-color-orangeReal details-header__check">fan fa-check</v-icon>-->
<!--                Non-steroidal</li>-->
<!--            </ul>-->
<!--&lt;!&ndash;	          <span class="call-to-action"></span>&ndash;&gt;-->
<!--						<div class="titled__info">AND covered by many <span class="text-decoration-underline">insurances including Medicare</span></div>-->
<!--            <div class="form">-->
<!--              <div class="titled__info text-color-orangeReal text-center">Ask us how we can help YOU today!-->
<!--&lt;!&ndash;	              <span class="call-to-action&#45;&#45;orange raleway product-name">Fluid GF™</span>&ndash;&gt;-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="titled__info text-center">See if this <span class="call-to-action">REGENERATIVE</span> option is the right solution for you! </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <v-text-field&ndash;&gt;-->
<!--&lt;!&ndash;                  class="form__input"&ndash;&gt;-->
<!--&lt;!&ndash;                  background-color="white"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="fullName"&ndash;&gt;-->
<!--&lt;!&ndash;                  @input="missingInfo = false; success = false;"&ndash;&gt;-->
<!--&lt;!&ndash;                  filled&ndash;&gt;-->
<!--&lt;!&ndash;                  append-icon="fa fa-user"&ndash;&gt;-->
<!--&lt;!&ndash;                  label="Enter Your Full Name"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;              <v-text-field&ndash;&gt;-->
<!--&lt;!&ndash;                  class="form__input"&ndash;&gt;-->
<!--&lt;!&ndash;                  background-color="white"&ndash;&gt;-->
<!--&lt;!&ndash;                  @input="missingInfo = false; success = false;"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="email"&ndash;&gt;-->
<!--&lt;!&ndash;                  filled&ndash;&gt;-->
<!--&lt;!&ndash;                  append-icon="fa fa-envelope"&ndash;&gt;-->
<!--&lt;!&ndash;                  label="Enter Your Email Address"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-if="success" class="success">Done, you should have been forwarded to a form... let us know if anything.</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="error-state" v-if="missingInfo">Please fill out both the above.</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <v-btn @click="save" :color="'#00aeef' || '#fb7c34'" dark large class="ready-btn">I'm Ready for RELIEF</v-btn>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="testimonials">-->
<!--        <div class="testimonial">-->
<!--          <div class="titled text-color-green">Patient</div>-->
<!--          <div class="video">-->
<!--            <iframe width="256" height="144" src="https://www.youtube.com/embed/cEVxYQ9tHx0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="testimonial">-->
<!--          <div class="titled text-color-green">Patient Advocate</div>-->
<!--          <div class="video">-->
<!--            <iframe width="256" height="144" src="https://www.youtube.com/embed/hb-YQQ4nahY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="testimonial">-->
<!--          <div class="titled text-color-green">Nurse Practitioner</div>-->
<!--          <div class="video">-->
<!--            <iframe width="256" height="144" src="https://www.youtube.com/embed/Ptwhzo3eoo4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import { analytics, db } from "@/helpers/firebase";
import format from "date-fns/format";

const bg = require('@/assets/landingbg.webp');
const shoulder = require('@/assets/shoulder-min.png');
const knee = require('@/assets/knee-min.png');
const elbo = require('@/assets/elbo-min.png');

const dbCollectionFormName = 'prospects';
const dbCollectionRenderName = 'landingPages';

export default {
  name: 'prequalify',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'BioLab Sciences Fluid GF see if you qualify',
    meta: [
      {
        name: 'description',
        content: 'Use this form to see if you qualify as someone who could benefit from Fluid GF.'
      },
    ],
    // all titles will be injected into this template
    titleTemplate: '%s | Biolab fluid gf Q4206'
  },
  docs(v) {
    return {
      props: v.props,
      events: {},
      slots: {},
      components: v.components,
    };
  },
  props: {},
  data() {
    return {
      bg,
      circles: {
				first: shoulder,
				second: knee,
				third: elbo,
      },
      fullName: '',
      email: '',
      landingID: null,
      missingInfo: false,
      success: false,
      // logo, contactNumber, and form info here
      landingInfo: {},
      genericLandingData: {
        companyID: null,
        contactNumber: '',
        formEmailID: '1780460577',
        formNameID: '1449005772',
        logo: "",
        formURL: "https://docs.google.com/forms/d/e/1FAIpQLSc-30pa2_C3dkxre8AiQDOL83gUzC2XB3174zmDWKbzxzooVQ/viewform?usp=pp_url"
      }
    };
  },
  created() {
    this.landingID = this.$route.query.l || this.$route.params.landingID;
    this.getLandingInfo();
  },
  methods: {
    getLandingInfo() {
      if (this.landingID) {
        db.collection(dbCollectionRenderName).doc(this.landingID).get().then(doc => {
          const data = doc.data();
          this.landingInfo = data || this.genericLandingData;
        }).catch(err => {
          console.error(err);
        });
      }
    },
    save() {
      if (this.fullName && this.email) {
        const date = new Date();
        db.collection(dbCollectionFormName).add({
          name: this.fullName,
          email: this.email,
          landingID: this.landingID || 'unknownID',
          leadFinished: date.toISOString(),
          leadFinishedLocalDate: format(date, 'yyyy-MM-dd'),
          leadFinishedLocalTime: format(date, 'HH:mm'),
        }).then(() => {
          analytics.logEvent(`landingPage_lead_${this.landingID || 'unknownID'}`);
          if (this.landingInfo.formURL) {
            let url = this.landingInfo.formURL;
            if (this.landingInfo.formEmailID) {
              url += `&entry.${this.landingInfo.formEmailID}=${this.email}`;
            }
            if (this.landingInfo.formNameID) {
              url += `&entry.${this.landingInfo.formNameID}=${this.fullName}`;
            }
            this.fullName = '';
            this.email = '';
            this.success = true;
            window.open(url);
          }
        })
      } else {
        this.missingInfo = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Raleway');
.under-maintain {
  font-size: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.titled__sub {
	margin-top: .8rem;
}
.titled__list {
	margin-bottom: .8rem;
}

.raleway {
  font-family: Raleway, sans-serif;
}
$pageMaxWidth: 980px;

.text-color-white {
  color: green;
}

.prequalify {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.error-state {
  color: red;
}



.circles {
	.circle {
		max-width: 194px;
		margin-bottom: 1rem;
		@media screen and (max-width: 599px) {
			max-width: 100px;
		}
		&:not(:first-child) {
			margin-left: -2rem;
		}
	}
}

.bg-image {
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  background: white;
  box-shadow: 0 2px 5px 2px rgba(0,0,0,0.4);
  position: relative;
  &__container {
    max-width: 1220px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .contact {
    font-size: 22px;
    .call-out {
      font-weight: bold;
    }
  }
  .logo {
    height: 100%;
    img {
      max-height: 100%;
    }
  }
}

.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 49.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 5px 2px rgba(0,0,0,0.4);
  max-width: $pageMaxWidth;
  width: 100%;
  margin-top: 1rem;
  border-radius: 10px;
  background: rgba(0, 43, 67, 0.49);
  color: white;
  padding: 2rem;
}
.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  .right {
    max-width: 365px;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.64);;
    border-radius: 10px;
    box-shadow: 0 2px 5px 2px rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    .sub {
      font-size: 20px;
      margin-top: 1rem;
    }
    .titled {
      font-size: 24px;
      &__list {
        list-style-type: none;
        li {
          margin: .5rem 0;
        }
      }
    }
    .call-to-action {
      color: #00aeef;
      //color: #fb7c34;
      &--orange {
        color: orange;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 24px;
      }
    }
    .form {
      margin-top: 1.5rem;
      width: 100%;
      .ready-btn {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .left {
    padding: 0 1rem;
    .top {
      width: 100%;
			@media screen and (max-width: 600px){
				min-width: 300px;
			}
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #92b5d0;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
    .bottom {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      .title {
        width: 100%;
      }
      .content {
        &__container {
          margin-left: 1rem;
          margin-right: 1rem;
          .title {
            margin-bottom: 1rem;
          }
        }
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        .list {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-bottom: 2rem;
          color: rgb(207 216 223);
        }
        .call-out {
          display: flex;
          justify-content: center;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}
.call-out {
  &.extra-big {
    font-size: 36px!important;
  }
}
.testimonials {
  background: white;
  padding: 1rem;
  width: 100%;
  max-width: $pageMaxWidth;
  border-radius: 10px;
  margin-top: 1rem;
  box-shadow: 0 2px 5px 2px rgba(0,0,0,0.4);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .titled {
    text-align: center;
    font-size: 24px;
  }
}

.main-call-out {
	font-size: 1.3em;
	.titled__info, .form {
		text-align: center;
	}
	.form {
		font-size: 1.5em;
		.product-name {
			font-size: 1.2em;
			font-weight: bold;
		}
	}
}

</style>
